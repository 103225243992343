import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { Flex, Input, Typography } from 'antd';
import e from "cors";
const { Title } = Typography;

const Footer = () => {
    const [loginNextUI, setLoginNextUI] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [registerOTP_UI, setRegisterOTP_UI] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [registerValues, setRegisterValues] = useState({ name: "", email: "" });
    const [OTPvalue, setOTPvalue] = useState();
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isRegisterEmpty, setIsRegisterEmpty] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //Get phone number
    const handlePhoneNumberChange = (e) => {
        if (/^\d*$/.test(e.target.value)) {
            setIsPhoneEmpty(false);
            setPhoneNumber(e.target.value)
        }
    }

    //Get Register values
    const handleRegisterValues = (e) => {
        setIsRegisterEmpty(false);
        const name = e.target.name;
        const value = e.target.value;
        setRegisterValues({ ...registerValues, [name]: value });
    }

    //Get OTP values
    const handleRegisterOTPvalue = (value) => {
        setOTPvalue(value);
    }

    //Check if Number exist or not
    const handleCheckLogin = async (e) => {
        try {
            e.preventDefault();
            if (!phoneNumber || phoneNumber.length !== 10) {
                setIsPhoneEmpty(true);
                return;
            }
            setLoading(true);
            const res = await axios.post("https://cashknock.in/admin/api/userlogin2", {
                "mobile_email": phoneNumber,
            })
            setLoading(false);
            // console.log(res.data);
            if (res.data.status === 1) {
                toast.success(res.data.message);
                setLoginNextUI(true);
                setIsRegister(true);
            } else if (res.data.status === 0) {
                setLoginNextUI(true);
            } else {
                toast.error("Your account is disabled !")
            }
        } catch (err) {
            console.log(err);
        }
    }

    //Login OTP verification
    const handleLogin = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            console.log(OTPvalue)
            const res = await axios.post("https://cashknock.in/admin/api/verify-login-otp", {
                mobile_email: phoneNumber,
                otp: OTPvalue
            });
            setLoading(false);

            if (res.data.status === 0) {
                toast.error(res.data.message);
            } else {
                toast.success(res.data.message);
                localStorage.setItem('login-token', res.data.data.token);
                localStorage.setItem('name', res.data.data.name);
                localStorage.setItem('email', res.data.data.email);
                localStorage.setItem('user_id', res.data.data.user_id);
                window.location.reload();
            }


        } catch (error) {
            if (error.res) {
                toast.error("It seems server is busy.");
            }
        }
    }

    //Generate register OTP
    const handleRegisterOTP = async (e) => {
        try {
            e.preventDefault();
            if (!registerValues.name || !registerValues.email) {
                setIsRegisterEmpty(true);
                return;
            }
            setLoading(true);
            const res = await axios.post("https://cashknock.in/admin/api/register-otp", {
                name: registerValues.name,
                email: registerValues.email,
                mobile_no: phoneNumber
            })
            setLoading(false);
            console.log(res);
            if (res.data.status === 1) {
                setRegisterOTP_UI(true);
                toast.success(res.data.message);
            } else {
                toast.error(res.data.data[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    //Register OTP verification
    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const res = await axios.post("https://cashknock.in/admin/api/verify-otp", {
                email: registerValues.email,
                otp: OTPvalue
            });
            setLoading(false);

            if (res.data.status === 0) {
                toast.error(res.data.message);
            } else {
                toast.success(res.data.message);
                localStorage.setItem('login-token', res.data.data.token);
                localStorage.setItem('name', res.data.data.name);
                localStorage.setItem('email', res.data.data.email);
                localStorage.setItem('user_id', res.data.data.user_id);
                window.location.reload();
            }

        } catch (err) {
            console.log(err);
        }
    }

    const onChange = (text) => {
        console.log('onChange:', text);
    };
    const sharedProps = {
        onChange,
    };

    // Handle close button click
    const handleClose = () => {
        setLoginNextUI(false);
        setIsPhoneEmpty(false);
        setIsRegister(false);
        setIsRegisterEmpty(false);
        setPhoneNumber("");
        setRegisterValues({
            name: "", email: ""
        })
        setRegisterOTP_UI(false);
    };




    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 footer-contact">
                                <div className="f-logo"> <Link to="/">
                                    <img src="../assets/img/f-logo.webp" alt="cashknock logo" className="img-fluid" />
                                    
                                    </Link> 
                                    </div>
                                <p>We offer high-quality service and the best delivery service, and the gadget market you can blindly trust</p>
                            </div>
                            <div className="col-lg-2 col-md-6 col-6 footer-links">

                                <h4>Useful Links</h4>
                                <ul>
                                    {/* <li><i className="bx bx-chevron-right"></i> <a href="about.html">About Us</a></li>
                                     */}
                                    <li onClick={handleLinkClick}>
                                        <i className="bx bx-chevron-right"></i>
                                        <Link to="/about" className="back-to-top-1">About</Link>
                                    </li>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        <Link to="/contact" className="back-to-top-1">Contact Us</Link>
                                    </li>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        <Link to="/support" className="back-to-top-1">Support</Link>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6 col-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        <Link to="/terms-of-service" className="back-to-top-1">Terms of Service</Link>
                                    </li>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        {/* <a href="privacy.html">Privacy Policy</a> */}
                                        <Link to="/privacy" className="back-to-top-1">Privacy Policy</Link>
                                    </li>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        <Link to="/blogs" className="back-to-top-1">Blogs</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Contact Us</h4>
                                <div className="cont-details">
                                    <p><i className="bi bi-pin-map-fill">
                                        </i> D-54, Second Floor, Sector - 2, Noida</p>
                                    <a href="tel:+919876543210"><i className="bi bi-telephone-inbound-fill"></i> +91 8800390953</a> <a href="mailto:support@cashknock.in">
                                        <i className="bi bi-envelope-at-fill"></i> 
                                        support@cashknock.in
                            
                                        </a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container d-lg-flex py-3 align-items-center">
                    <div className="me-lg-auto text-center text-lg-start">
                        <div className="copyright"> Copyright 2024<strong><span> Cashknock</span></strong> | All Rights Reserved | <span>Design & Developed By <a href="https://www.coderworldlabs.com/">CoderWorld Labs</a></span> </div>
                    </div>
                    <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
                        {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>  */}
                        <a href="https://www.facebook.com/share/14UD19CMby/?mibextid=LQQJ4d" className="facebook" target="_blank"><i className="bx bxl-facebook"></i></a>
                        <a href="https://www.instagram.com/cashknock" className="instagram" target="_blank"><i className="bx bxl-instagram"></i></a>
                        {/* <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>  */}
                        {/* <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>  */}
                    </div>
                </div>
            </footer>


            <div class="modal login" data-bs-backdrop="static"
                data-bs-keyboard="false" id="loginModel">
                <div class="modal-dialog"
                // modal-dialog-centered"
                >
                    <div class="modal-content">
                        <form className="register-form" >
                            <div class="modal-header d-md-none">
                                <h4 class="modal-title">Login/Signup</h4>
                                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" onClick={handleClose}></button>
                            </div>

                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12 d-none d-md-flex flex-column justify-content-between gap-4 login-side" >
                                    <h4 class="modal-title">Login/Signup</h4>
                                    <img src="/assets/img/login-transformed.webp" alt="login" />
                                </div>
                                <div class="col-lg-8 col-md-8 col-12 login-inputs">
                                    <button type="button" class="btn-close float-end" data-bs-dismiss="modal" onClick={handleClose} ></button>
                                    {
                                        loginNextUI ?
                                            <>
                                                {
                                                    isRegister ?
                                                        <>
                                                            <Flex align="flex-start" vertical>
                                                                <Input.OTP length={6} {...sharedProps}
                                                                    onChange={handleRegisterOTPvalue}
                                                                    inputType="numeric"
                                                                />
                                                            </Flex>


                                                            {loading ?
                                                                <>
                                                                    <h6 className="text-success mt-2">Loading...</h6>
                                                                    <div class="modal-footer login-footer login-new-footer pe-none opacity-75">
                                                                        <button className="modal-c-btn" onClick={handleLogin}>Login</button>
                                                                    </div></> :
                                                                <>
                                                                    <label style={{ color: 'green', marginTop: "1rem" }}>Didn't get the code? <button class="resend-btn" onClick={handleCheckLogin}>Resend</button></label>
                                                                    <div class="modal-footer login-footer login-new-footer">
                                                                        <button className="modal-c-btn" onClick={handleLogin}>Login</button>
                                                                    </div></>}
                                                        </> :
                                                        <>
                                                            {
                                                                registerOTP_UI ?
                                                                    <>
                                                                        <Flex align="flex-start" vertical>
                                                                            <Input.OTP length={6} {...sharedProps}
                                                                                onChange={handleRegisterOTPvalue} />
                                                                        </Flex>


                                                                        {loading ?
                                                                            <>
                                                                                <h6 className="text-success mt-2">Loading...</h6>
                                                                                <div class="modal-footer login-footer login-new-footer pe-none opacity-75">
                                                                                    <button className="modal-c-btn" onClick={handleLogin}>Register</button>
                                                                                </div></> :
                                                                            <>
                                                                                <label style={{ color: 'green', marginTop: "1rem" }}>Didn't get the code? <button class="resend-btn" onClick={handleCheckLogin}>Resend</button></label>
                                                                                <div class="modal-footer login-footer login-new-footer">
                                                                                    <button className="modal-c-btn" onClick={handleRegister}>Register</button>
                                                                                </div></>}
                                                                    </>
                                                                    : <>
                                                                        <div class="form-group mt-md-0 login-new">
                                                                            <h6 style={{ margin: "10px 0", }}>Enter your name</h6>
                                                                            <input
                                                                                class="form-field"
                                                                                type="text"
                                                                                placeholder="Enter your name"
                                                                                name="name"
                                                                                value={registerValues.name}
                                                                                onChange={handleRegisterValues}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                        <div class="form-group  mt-md-0 login-new">
                                                                            <h6 style={{ margin: "10px 0", }}>Enter your email</h6>
                                                                            <input
                                                                                class="form-field"
                                                                                type="email"
                                                                                placeholder="Enter your email"
                                                                                name="email"
                                                                                value={registerValues.email}
                                                                                onChange={handleRegisterValues}
                                                                                autoComplete="off"
                                                                            />

                                                                        </div>


                                                                        {
                                                                            isRegisterEmpty ? <>
                                                                                <h6 className="mt-2 text-danger"> Please input all the fields*</h6>
                                                                                <div class="modal-footer login-footer login-new-footer" >
                                                                                    <button className="modal-c-btn mb-0" onClick={handleRegisterOTP}>Register</button>
                                                                                </div></> : <>
                                                                                {
                                                                                    loading ? <>
                                                                                        <h6 className="text-success mt-2">Loading...</h6>
                                                                                        <div class="modal-footer login-footer login-new-footer pe-none opacity-75" >
                                                                                            <button className="modal-c-btn mb-0" onClick={handleRegisterOTP}>Register</button>
                                                                                        </div>
                                                                                    </> : <div class="modal-footer login-footer login-new-footer" >
                                                                                        <button className="modal-c-btn mb-0" onClick={handleRegisterOTP}>Register</button>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </> :
                                            <>
                                                <div class="form-group mt-md-0 login-new">
                                                    <h6 style={{ margin: "10px 0", }}>Enter your mobile number</h6>
                                                    <div className="d-flex align-items-center">
                                                        {/* <span style={{
                                                            fontWeight: 800, fontSize: "1.3rem",
                                                            marginBottom: "5px",
                                                            background: "#f5ffb9",
                                                            padding: "8.5px",
                                                            borderBottom: "2px solid #8b7e7e"
                                                        }}>+91</span> */}
                                                        <input
                                                            class="form-field"
                                                            type="text"
                                                            placeholder="Enter your phone"
                                                            name="mobile_email"
                                                            value={phoneNumber}
                                                            onChange={handlePhoneNumberChange}
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                        />
                                                    </div>

                                                </div>
                                                {isPhoneEmpty ? <>
                                                    <h6 className="text-danger mt-2">Please input the valid mobile number*</h6>
                                                    <div class="modal-footer login-footer login-new-footer">
                                                        <div class="checkbox-terms d-flex align-items-center gap-1">
                                                            <input
                                                                type="checkbox"
                                                                // name="agree_terms"
                                                                defaultChecked
                                                                className="pe-none"
                                                            />
                                                            <label class="form-check-label" for="exampleCheck1">I agree to the <a href="/terms-of-service" >Terms and Conditions</a> & <a href="/privacy">Privacy Policy</a></label>

                                                        </div>
                                                        <button className="modal-c-btn mb-0" onClick={handleCheckLogin}>Login</button>
                                                    </div></> :
                                                    <>
                                                        {loading ? <>
                                                            <h6 className="text-success mt-2">Loading...</h6>
                                                            <div class="modal-footer login-footer login-new-footer pe-none opacity-75">
                                                                <div class="checkbox-terms d-flex align-items-center gap-1">
                                                                    <input
                                                                        type="checkbox"
                                                                        // name="agree_terms"
                                                                        defaultChecked
                                                                        className="pe-none"
                                                                    />
                                                                    <label class="form-check-label" for="exampleCheck1">I agree to the <a href="/terms-of-service" >Terms and Conditions</a> & <a href="/privacy">Privacy Policy</a></label>

                                                                </div>
                                                                <button className="modal-c-btn mb-0" onClick={handleCheckLogin}>Login</button>
                                                            </div></> :
                                                            <>

                                                                <div class="modal-footer login-footer login-new-footer">
                                                                    <div class="checkbox-terms d-flex align-items-center gap-1">
                                                                        <input
                                                                            type="checkbox"
                                                                            // name="agree_terms"
                                                                            defaultChecked
                                                                            className="pe-none"
                                                                        />
                                                                        <label class="form-check-label" for="exampleCheck1">I agree to the <a href="/terms-of-service" >Terms and Conditions</a> & <a href="/privacy">Privacy Policy</a></label>

                                                                    </div>
                                                                    <button className="modal-c-btn mb-0" onClick={handleCheckLogin}>Login</button>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                    }
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;